:root {
  --btn-lh: 1.2;
  --btn-fw: 400;
  --btn-tt: uppercase;
  --btn-ls: 0.2em;
  --btn-vp: 1em;
  --btn-hp: 2em;
  --btn-bs: 200%;
  --btn-bp: 100%;
  --btn-gap: 0.25rem;
  --btn-trs-t: 0.3s;
}

.btn {
  display: inline-flex;
  font-size: var(--mn-fs);
  line-height: var(--btn-lh);
  font-weight: var(--btn-fw);
  text-transform: var(--btn-tt);
  letter-spacing: var(--btn-ls);
  padding: var(--btn-vp) var(--btn-hp);
  background-size: var(--btn-bs) calc(var(--btn-bs) / 2);
  background-position: calc(var(--btn-bp) * 0) calc(var(--btn-bp) * 0);
  color: var(--light);
  background-image: linear-gradient(
    to right,
    var(--primary-dark),
    var(--accent-dark)
  );
  transition: background-position var(--btn-trs-t) ease 0s;

  &.has-icon {
    gap: var(--btn-gap);
  }
}

// 1025px Responsive (min-width)
@media screen and (min-width: 1025px) {
  .btn:not(.skp-btn):hover,
  .btn:not(.skp-btn):focus {
    background-position: calc(var(--btn-bp) * 0.5) calc(var(--btn-bp) * 0);
  }
}
