html {
  background-color: var(--primary-dark);
}

body {
  color: var(--primary-light);
}

[class*="ttl-"] {
  color: var(--secondary-light);
}

.ttl-2.sub {
  color: var(--primary-light);
}

.drk-bg {
  background-color: var(--primary-dark);
}

.bg-bx-drk {
  background-color: var(--dark);
}

.bg-bx-lt {
  background-color: var(--secondary-dark);
}
