*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

html {
  line-height: 1;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

main {
  display: block;
}

ol,
ul {
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  list-style: none;
}

video {
  display: block;
}

img {
  border-style: none;
  max-width: 100%;
  display: block;
}

svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
}

iframe {
  border: none;
  display: block;
}

address {
  font-style: normal;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

a {
  background-color: transparent;
  text-decoration: none;
}

a[href^=tel] {
  cursor: default;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
}

b,
strong {
  font-weight: 600;
}

small {
  font-size: 75%;
  line-height: 1.333;
}

sub,
sup {
  font-size: 62.5%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: 0em;
}

sup {
  top: -0.5em;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

button,
select {
  text-transform: none;
}

button {
  all: unset;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

[type=checkbox],
[type=radio] {
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[hidden] {
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.mn {
  width: 100%;
  max-width: var(--mn-w);
  margin: 0 auto;
  position: relative;
  padding-left: var(--hp);
  padding-right: var(--hp);
}

a:not([class*=btn]) {
  transition: color var(--g-trs);
}

.full {
  width: var(--full);
}

.max-full {
  max-width: var(--full);
}

.half {
  width: var(--half);
}

.max-half {
  max-width: var(--half);
}

.third {
  width: var(--third);
}

.max-third {
  max-width: var(--third);
}

.two-thirds {
  width: var(--two-thirds);
}

.max-two-thirds {
  max-width: var(--two-thirds);
}

.fourth {
  width: var(--fourth);
}

.max-fourth {
  max-width: var(--fourth);
}

.three-fourths {
  width: var(--three-fourths);
}

.max-three-fourths {
  max-width: var(--three-fourths);
}

picture.img-bg,
picture.img-bg img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

picture.img {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

picture.img img {
  position: relative;
  width: 100%;
}

img[style*=background-image] {
  background-position: center;
  background-repeat: no-repeat;
}

img[style*=".png"],
img[style*=".webp"] {
  background-size: contain;
}

img[style*=".jpg"] {
  background-size: cover;
}

.cnt-stl p {
  margin-block-end: 1em;
  margin-block-start: 1em;
}
.cnt-stl > *:first-child {
  margin-top: 0;
}
.cnt-stl > *:last-child {
  margin-bottom: 0;
}

.scroll {
  overflow: auto;
}

.scroll::-webkit-scrollbar {
  width: var(--bdr-tqq);
}

.scroll::-webkit-scrollbar-thumb {
  background: var(--accent-dark);
}

.scroll::-webkit-scrollbar-track {
  background: var(--secondary-dark);
}

@media screen and (min-width: 1025px) {
  .fun-cursor {
    cursor: url("/assets/images/pew-pew.webp") 25 25, auto;
  }

  .fun-cursor:active {
    cursor: url("/assets/images/boom-boom.webp") 22 20, auto;
  }
}
@media screen and (min-width: 1921px) {
  html body {
    max-width: 1920px;
    margin: 0 auto;
    border: solid var(--bdr-tn) var(--secondary-dark);
  }
}
@media screen and (max-width: 1024px) {
  [class*=blk-1024] {
    display: block !important;
  }

  [class*=blk-1024] > * {
    width: 100% !important;
  }

  [class*=blk-1024][class*=-mgd] > *:not(.deco) + *:not(.deco) {
    margin-top: var(--vm) !important;
  }

  .ctr-1024 {
    text-align: center;
    justify-content: center !important;
  }
}
@media screen and (max-width: 800px) {
  .ctr-800 {
    text-align: center;
    justify-content: center !important;
  }

  .hd-800 {
    display: none !important;
    visibility: visible;
  }
}
@media screen and (max-width: 650px) {
  [class*=blk-650] {
    display: block !important;
  }

  [class*=blk-650] > *:not(.deco) {
    width: 100% !important;
  }

  [class*=blk-650][class*=-mgd] > *:not(.deco) + *:not(.deco) {
    margin-top: calc(var(--vm) * 0.7) !important;
  }
}
:root {
  --btn-lh: 1.2;
  --btn-fw: 400;
  --btn-tt: uppercase;
  --btn-ls: 0.2em;
  --btn-vp: 1em;
  --btn-hp: 2em;
  --btn-bs: 200%;
  --btn-bp: 100%;
  --btn-gap: 0.25rem;
  --btn-trs-t: 0.3s;
}

.btn {
  display: inline-flex;
  font-size: var(--mn-fs);
  line-height: var(--btn-lh);
  font-weight: var(--btn-fw);
  text-transform: var(--btn-tt);
  letter-spacing: var(--btn-ls);
  padding: var(--btn-vp) var(--btn-hp);
  background-size: var(--btn-bs) calc(var(--btn-bs) / 2);
  background-position: calc(var(--btn-bp) * 0) calc(var(--btn-bp) * 0);
  color: var(--light);
  background-image: linear-gradient(to right, var(--primary-dark), var(--accent-dark));
  transition: background-position var(--btn-trs-t) ease 0s;
}
.btn.has-icon {
  gap: var(--btn-gap);
}

@media screen and (min-width: 1025px) {
  .btn:not(.skp-btn):hover,
.btn:not(.skp-btn):focus {
    background-position: calc(var(--btn-bp) * 0.5) calc(var(--btn-bp) * 0);
  }
}
html {
  font-size: 1.145835vw;
}

body {
  font-family: var(--mn-f);
  line-height: var(--lh);
  font-weight: var(--fw);
}

.mf {
  display: block;
  margin: 0;
  font-size: var(--mn-fs);
  font-style: normal;
}

[class*=ttl-] {
  display: block;
}

.ttl-1 {
  font-family: var(--ttl-f);
  font-size: var(--ttl-1-fs);
  font-weight: var(--ttl-1-fw);
  line-height: var(--ttl-1-lh);
  letter-spacing: var(--ttl-1-ls);
  text-transform: var(--ttl-1-tt);
}

.ttl-1.sm {
  font-size: var(--ttl-1-sm-fs);
}

.ttl-2 {
  font-size: var(--mn-fs);
  line-height: var(--ttl-2-lh);
  font-weight: var(--ttl-2-fw);
  letter-spacing: var(--ttl-2-ls);
  text-transform: var(--ttl-2-tt);
}

.ttl-2.dft {
  line-height: var(--lh);
}

.ttl-2.sub {
  font-weight: var(--ttl-2-sub-fw);
}

@media screen and (min-width: 1921px) {
  html {
    font-size: 22px;
  }
}
@media screen and (max-width: 1200px) {
  html {
    font-size: calc(14px + 3 * (100vw - 801px) / 399);
  }
}
@media screen and (max-width: 800px) {
  html {
    font-size: calc(16px + 2 * (100vw - 501px) / 299);
  }
}
@media screen and (max-width: 500px) {
  html {
    font-size: calc(15px + 2 * (100vw - 320px) / 180);
  }
}
html {
  background-color: var(--primary-dark);
}

body {
  color: var(--primary-light);
}

[class*=ttl-] {
  color: var(--secondary-light);
}

.ttl-2.sub {
  color: var(--primary-light);
}

.drk-bg {
  background-color: var(--primary-dark);
}

.bg-bx-drk {
  background-color: var(--dark);
}

.bg-bx-lt {
  background-color: var(--secondary-dark);
}

:root {
  --mstg-tp: 4.227em;
  --mn-w: 79.6467rem;
  --bdr-tqq: 3px;
  --bdr-tn: 2px;
  --g-trs: 0.3s ease 0s;
  --vp: 3rem;
  --hp: 4%;
  --vm: 2.5rem;
  --full: 100%;
  --half: 50%;
  --third: 33.333%;
  --two-thirds: 66.667%;
  --fourth: 25%;
  --three-fourths: 75%;
  --primary-dark: #282843;
  --secondary-dark: #313154;
  --primary-light: #9595b8;
  --secondary-light: #ffffff;
  --accent-light: #e79aaf;
  --accent-dark: #fb1496;
  --accent-fallback: #e75d9f;
  --light: #ffffff;
  --dark: #25253e;
  --dark-alt: #191f24;
  --lh: 2.308;
  --fw: 400;
  --mn-f: "Heebo", sans-serif;
  --ttl-f: "Montserrat", sans-serif;
  --mn-fs: 13px;
  --ttl-1-fs: 1.364rem;
  --ttl-1-lh: 1;
  --ttl-1-fw: 400;
  --ttl-1-tt: uppercase;
  --ttl-1-ls: 0.2em;
  --ttl-1-sm-fs: 1rem;
  --ttl-2-lh: 1.4;
  --ttl-2-fw: 400;
  --ttl-2-tt: uppercase;
  --ttl-2-ls: 0.2em;
  --ttl-2-sub-fw: 700;
}

@media screen and (min-width: 1201px) {
  :root {
    --bdr-sz: 7.727rem;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --ttl-1-fs: 1.667rem;
    --ttl-1-sm-fs: 1.222rem;
    --font-bump: 1.222em;
    --bdr-sz: 4em;
  }
}
@media screen and (max-width: 650px) {
  :root {
    --hp: 6%;
  }
}
.hd {
  display: none !important;
}

.hdn {
  overflow: hidden;
}

.rel {
  position: relative;
}

.m-ato {
  margin-left: auto;
  margin-right: auto;
}

.mt-ato {
  margin-top: auto;
}

.dp\:blk {
  display: block;
}
.dp\:inl-blk {
  display: inline-block;
}

.ta\:l {
  text-align: left;
}
.ta\:r {
  text-align: right;
}
.ta\:c {
  text-align: center;
}

.bg {
  position: absolute;
  pointer-events: none;
  inset: 0;
}

.psu-bfr::before,
.psu-aft::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  pointer-events: none;
}

.pc-out {
  height: 0;
  width: 0;
  visibility: hidden;
  opacity: 0;
  display: block;
  position: absolute;
}

.usr-scl {
  user-select: none;
}

[class*=nlf] {
  display: flex;
}
[class*=nlf][class*=-wrap] {
  flex-wrap: wrap;
}
[class*=nlf] .fit {
  flex: 0 0 auto;
}
[class*=nlf] .auto {
  flex: 1 1 auto;
}
[class*=nlf] .fit-auto {
  flex: 0 1 auto;
}
[class*=nlf] .auto.fit, [class*=nlf] .auto-fit {
  flex: 1 0 auto;
}
[class*=nlf][class*=-inline] {
  display: inline-flex;
}
[class*=nlf][class*=-center] {
  justify-content: center;
}
[class*=nlf][class*=-end] {
  justify-content: flex-end;
}
[class*=nlf][class*=-start] {
  justify-content: flex-start;
}
[class*=nlf][class*=-between] {
  justify-content: space-between;
}
[class*=nlf][class*=-around] {
  justify-content: space-around;
}
[class*=nlf][class*=-evenly] {
  justify-content: space-evenly;
}
[class*=nlf][class*=-middle] {
  align-items: center;
}
[class*=nlf][class*=-top] {
  align-items: flex-start;
}
[class*=nlf][class*=-baseline] {
  align-items: baseline;
}
[class*=nlf][class*=-bottom] {
  align-items: flex-end;
}
[class*=nlf][class*=-row] {
  flex-direction: row;
}
[class*=nlf][class*=-reverse] {
  flex-direction: row-reverse;
}
[class*=nlf][class*=-column] {
  flex-direction: column;
}
[class*=nlf][class*=-reverse][class*=-column] {
  flex-direction: column-reverse;
}
[class*=nlf][class*=-stretch], [class*=nlf] > .stretch-self {
  align-self: stretch;
}

.vp\: {
  padding-top: var(--vp);
  padding-bottom: var(--vp);
}
.vp\:sm {
  padding-top: calc(var(--vp) * 0.5);
  padding-bottom: calc(var(--vp) * 0.5);
}
.vp\:tn {
  padding-top: calc(var(--vp) * 0.3562);
  padding-bottom: calc(var(--vp) * 0.3562);
}
.vp\:lg {
  padding-top: calc(var(--vp) * 1.088);
  padding-bottom: calc(var(--vp) * 1.088);
}

.hp\: {
  padding-left: var(--hp);
  padding-right: var(--hp);
}
.hp\:sm {
  padding-left: calc(var(--hp) * 0.5);
  padding-right: calc(var(--hp) * 0.5);
}

.mt\: {
  margin-top: var(--vm);
}
.mt\:sm {
  margin-top: calc(var(--vm) * 0.5);
}
.mt\:lg {
  margin-top: calc(var(--vm) * 1.2);
}

.mb\: {
  margin-bottom: var(--vm);
}
.mb\:sm {
  margin-bottom: calc(var(--vm) * 0.5);
}
.mb\:lg {
  margin-bottom: calc(var(--vm) * 1.2);
}