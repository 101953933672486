*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

html {
  line-height: 1;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

main {
  display: block;
}

ol,
ul {
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  list-style: none;
}

video {
  display: block;
}

img {
  border-style: none;
  max-width: 100%;
  display: block;
}

svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
}

iframe {
  border: none;
  display: block;
}

address {
  font-style: normal;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

a {
  background-color: transparent;
  text-decoration: none;
}

a[href^="tel"] {
  cursor: default;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
}

b,
strong {
  font-weight: 600;
}

small {
  font-size: 75%;
  line-height: 1.333;
}

sub,
sup {
  font-size: 62.5%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: 0em;
}

sup {
  top: -0.5em;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

button,
select {
  text-transform: none;
}

button {
  all: unset;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

[type="checkbox"],
[type="radio"] {
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[hidden] {
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
