.mn {
  width: 100%;
  max-width: var(--mn-w);
  margin: 0 auto;
  position: relative;
  padding-left: var(--hp);
  padding-right: var(--hp);
}

a:not([class*="btn"]) {
  transition: color var(--g-trs);
}

// sizes
.full {
  width: var(--full);
}

.max-full {
  max-width: var(--full);
}

.half {
  width: var(--half);
}

.max-half {
  max-width: var(--half);
}

.third {
  width: var(--third);
}

.max-third {
  max-width: var(--third);
}

.two-thirds {
  width: var(--two-thirds);
}

.max-two-thirds {
  max-width: var(--two-thirds);
}

.fourth {
  width: var(--fourth);
}

.max-fourth {
  max-width: var(--fourth);
}

.three-fourths {
  width: var(--three-fourths);
}

.max-three-fourths {
  max-width: var(--three-fourths);
}

// pictures
picture.img-bg,
picture.img-bg img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

picture.img {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

picture.img img {
  position: relative;
  width: 100%;
}

img[style*="background-image"] {
  background-position: center;
  background-repeat: no-repeat;
}

img[style*=".png"],
img[style*=".webp"] {
  background-size: contain;
}

img[style*=".jpg"] {
  background-size: cover;
}

// content
.cnt-stl {
  p {
    margin-block-end: 1em;
    margin-block-start: 1em;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

// scroll
.scroll {
  overflow: auto;
}

.scroll::-webkit-scrollbar {
  width: var(--bdr-tqq);
}

.scroll::-webkit-scrollbar-thumb {
  background: var(--accent-dark);
}

.scroll::-webkit-scrollbar-track {
  background: var(--secondary-dark);
}

// 1025px Responsive (min-width)
@media screen and (min-width: 1025px) {
  .fun-cursor {
    cursor: url("/assets/images/pew-pew.webp") 25 25, auto;
  }

  .fun-cursor:active {
    cursor: url("/assets/images/boom-boom.webp") 22 20, auto;
  }
}

// 1921px Responsive (min-width)
@media screen and (min-width: 1921px) {
  html body {
    max-width: 1920px;
    margin: 0 auto;
    border: solid var(--bdr-tn) var(--secondary-dark);
  }
}

// 1024px Responsive (max-width)
@media screen and (max-width: 1024px) {
  [class*="blk-1024"] {
    display: block !important;
  }

  [class*="blk-1024"] > * {
    width: 100% !important;
  }

  [class*="blk-1024"][class*="-mgd"] > *:not(.deco) + *:not(.deco) {
    margin-top: var(--vm) !important;
  }

  .ctr-1024 {
    text-align: center;
    justify-content: center !important;
  }
}

// 800px Responsive (max-width)
@media screen and (max-width: 800px) {
  .ctr-800 {
    text-align: center;
    justify-content: center !important;
  }

  .hd-800 {
    display: none !important;
    visibility: visible;
  }
}

// 650px Responsive (max-width)
@media screen and (max-width: 650px) {
  [class*="blk-650"] {
    display: block !important;
  }

  [class*="blk-650"] > *:not(.deco) {
    width: 100% !important;
  }

  [class*="blk-650"][class*="-mgd"] > *:not(.deco) + *:not(.deco) {
    margin-top: calc(var(--vm) * 0.7) !important;
  }
}
